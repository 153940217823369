<template>
  <div
    v-if="$canAndRulesPass(user, $permissions.USER.ORGSTRUCT_VIEW)"
    v-loading="loading"
    style="position: relative"
  >

    <orgstruct-tree
      ref="orgstructTree"
      :datasource="ds"
      pan
      zoom
      children-field="subordinates"
      position-field="position"
      fio-field="full_name"
      :show-cards="showCards"
      :toggle-show-functional-chief="toggleShowFunctionalChief"
      :current-user-id="currentUserId"
      @load-parent="loadParent"
      @load-child="loadChild"
    >
    </orgstruct-tree>

    <div class="orgstruct-menu">
      <div>
        <add-users-dialog
          single-select
          title-text="Поиск сотрудника"
          add-button-text="Нажмите на строку для выбора"
          @users-selected="loadInit($event.id)"
        >
          <el-button
            slot="reference"
            type="primary"
            size="mini"
            icon="fas fa-search"
          >
            Найти сотрудника
          </el-button>
        </add-users-dialog>
      </div>
      <div>

        <el-checkbox-group size="small" v-model="toggleShowFunctionalChief" style="display: inline-block; margin-right: 10px">
          <el-checkbox-button>
            <i style="margin-right: 8px" class="fas fa-table"></i>
            <span v-if="toggleShowFunctionalChief">Скрыть ФР</span>
            <span v-else>Открыть ФР</span>
          </el-checkbox-button>
        </el-checkbox-group>
        <el-checkbox-group size="small" v-model="showCards" style="display: inline-block">
          <el-checkbox-button>
            <i style="margin-right: 8px" class="fas fa-table"></i>
            <span v-if="showCards">Скрыть показатели</span>
            <span v-else>Открыть показатели</span>
          </el-checkbox-button>
        </el-checkbox-group>
      </div>
    </div>


  </div>

</template>

<script>

import {mapGetters} from "vuex";
import OrgstructTree from "@/components/orgstruct/OrgstuctTree.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";

export default {
  name: 'Orgstruct',

  components: {AddUsersDialog, OrgstructTree},

  data() {
    return {
      loading: false,

      ds: {},
      currentUserId: null,

      showCards: true,
      toggleShowFunctionalChief: true,

    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    this.loadInit();
  },
  methods: {
    loadInit(id) {
      this.loading = true;
      this.$api.orgstruct.get({
        id: id,
        expand: [
          'chief_direct', 'chief_direct.card_elements.scale', 'chief_direct.chief_functional',
          'immediate_employees', 'immediate_employees.card_elements.scale', 'immediate_employees.chief_functional',
          'chief_functional', 'card_elements.scale',
        ].join(),
      })
        .then(data => {
          this.currentUserId = data.user.id;
          let tree = {};

          if (data.user.immediate_employees) {
            data.user.subordinates = data.user.immediate_employees;
            delete data.user.immediate_employees;
          }

          if (data.user.chief_direct) {
            tree = data.user.chief_direct;
            tree.subordinates = [data.user];
          } else {
            tree = data.user;
          }

          this.ds = tree;
        })
        .finally(() => {
          this.loading = false;
        })
    },


    loadParent(node) {
      this.loading = true;
      this.$api.orgstruct.get({
        id: node.id,
        expand: [
          'chief_direct', 'chief_direct.card_elements.scale',
          'chief_direct.chief_functional', 'chief_direct.chief_functional.card_elements.scale',
          'chief_direct.immediate_employees', 'chief_direct.immediate_employees.card_elements.scale',
        ].join(),
      })
        .then(data => {
          let tree = {};

          if (!data.user.chief_direct) {
            return;
          }

          tree = data.user.chief_direct;
          tree.subordinates = data.user.chief_direct.immediate_employees;

          tree.subordinates.forEach(sub => {
            if (sub.id == node.id) {
              sub.subordinates = node.subordinates;
            }
          })

          this.ds = tree;
        })
        .finally(() => {
          this.loading = false;
        })
    },


    loadChild(node) {
      this.loading = true;
      this.$api.orgstruct.get({
        id: node.id,
        expand: [
          'immediate_employees', 'immediate_employees.card_elements.scale',
          'immediate_employees.chief_functional', 'immediate_employees.chief_functional.card_elements.scale',
        ].join(),
      })
        .then(data => {
          this.$set(node, 'subordinates', data.user.immediate_employees);
        })
        .finally(() => {
          this.loading = false;
        })
    },


  }
}

</script>

<style>
.orgchart-container {
  height: 89vh;
}

.orgstruct-menu {
  width: 93vw;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: space-between;
}
</style>